
<template>
  <v-hover v-slot="{ hover }" class="ml-n6">
    <div class="d-flex">
      <div style="min-width: 25px">
        <v-icon v-if="hover" max-width="25" height="20" @click="setFilter()"> mdi-magnify</v-icon>
      </div>
      <router-link
        :to="
         result.testGroupPath +
          row.n_id
        "
      >
        {{ row[header] }}
      </router-link>
    </div>
  </v-hover>
</template>
<script>
export default {
  props: ["row", "header","currentTable","result"],
  methods:{setFilter(){
    this.$set(this.currentTable,"searchValue",this.row[this.header])

  }}
};
</script>
